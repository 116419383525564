import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="高リスク双極性障害における認知機能" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          第11回 高リスク双極性障害における認知機能
        </h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6319152333112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham教授 (ブリティッシュコロンビア大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>Allan Young教授 (キングスカレッジロンドン)</span>
            </p>
          </div>
          <Navigation prev="2" next="4" />
          <div className={styles.paragraph}>
            <p>
              双極性障害の高リスクとは何か、高リスク群において認知機能がいかに障害されているかを考えます。
              <br />
            </p>
            <div>
              <span>＜学びのポイント＞</span>
              <ul>
                <li>双極性障害の高リスク群の遺伝的、臨床的特徴</li>
                <li>高リスク群が示す認知機能障害</li>
                <li>実臨床での高リスク群の検出と認知機能障害に対する対処</li>
              </ul>
            </div>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'高リスク双極性障害における認知機能'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
